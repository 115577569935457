import {
  Box,
  Button,
  ChakraProvider,
  Container,
  Heading,
  SimpleGrid,
  useToast
} from "@chakra-ui/react";
import "./styles.css";

import "@fontsource/manrope/200.css";
import "@fontsource/manrope/300.css";
import "@fontsource/manrope/400.css";
import "@fontsource/manrope/500.css";
import "@fontsource/manrope/600.css";
import "@fontsource/manrope/700.css";
import "@fontsource/manrope/800.css";

const multiples = Array.from({ length: 515 }, (_, i) => i * 8);

export default function App() {
  const toast = useToast();

  function handleTextCopy(text) {
    navigator.clipboard.writeText(text);
    toast({
      description: `${text} copied to clipboard!`,
      position: "top-left",
      status: "success"
    });
  }

  return (
    <ChakraProvider>
      <Container my="16">
        <Box my="8" textAlign="center">
          <Heading>Otso</Heading>
          <h2>Ronna's Cheatsheet to Multiples of 8</h2>
        </Box>
        <Box my="10">
          <SimpleGrid columns={5}>
            {multiples.map((multiple) => (
              <Button
                key={multiple}
                variant="outline"
                size="sm"
                onClick={() => handleTextCopy(multiple)}
              >
                {multiple}
              </Button>
            ))}
          </SimpleGrid>
        </Box>
      </Container>
    </ChakraProvider>
  );
}
